@use 'variables' as *;
@use 'mixins';

.color-white {
  color: #fff !important;
}

.color-white-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.color-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.color-blue {
  color: $branding-color-blue;
}

.background-primary {
  background-color: var(--primary-color) !important;
}

.color-branding-dark-purple {
  color: $branding-color-dark-purple !important;
}

.color-gray {
  color: $color-gray;
}

.color-orange {
  color: $color-orange !important;
}

.color-faint-orange {
  color: transparentize($color-orange, 0.9);
}

.bg-faint-orange {
  background-color: transparentize($color-orange, 0.9);
}

.color-black {
  color: $branding-color-black !important;
}

.color-near-black {
  color: $branding-color-near-black !important;
}

.color-light-gray {
  color: rgba(0, 0, 0, .45) !important;
}

.color-lighter-gray {
  color: rgba(0, 0, 0, .20) !important;
}

.text-light-gray {
  color: $light-grey-text !important;
}

.text-med-gray {
  color: $med-grey-text !important;
}

.color-green {
  color: $color-green !important;
}

.color-orto-blue {
  color: $branding-orto-blue !important;
}

.color-faint-green {
  color: transparentize($color-green, 0.9);
}

.bg-faint-green {
  background-color: transparentize($color-green, 0.9);
}

.color-red {
  color: $color-red !important;
}

.hover-color-blue:hover {
  color: $branding-color-blue !important;
}

.background-blue {
  background-color: $branding-color-blue !important;
}

.border-top-blue {
  border-top: 6px solid $branding-color-blue !important;
}

.background-faint-blue {
  background-color: transparentize($branding-color-blue, 0.9);
}

.background-green {
  background-color: $color-green !important;
}

.background-orto-blue {
  background-color: $branding-orto-blue !important;
}

.border-top-green {
  border-top: 6px solid $color-green !important;
}

.background-faint-green {
  background-color: transparentize($color-green, 0.9);
}

.color-primary {
  color: var(--primary-color) !important;
}

.background-purple {
  background: $color-purple !important;
}

.border-top-purple {
  border-top: 6px solid $color-purple !important;
}

.background-white {
  background-color: white !important;
}

.background-near-black {
  color: $branding-color-near-black !important;
}

.background-red {
  background: $color-red !important;
}

.background-orange {
  background: $color-orange !important;
}

.background-faint-orange {
  background-color: transparentize($color-orange, 0.9);
}

.color-yellow {
  color: $color-yellow !important;
}

.color-faint-yellow {
  color: transparentize($color-yellow, 0.9);
}

.color-purple {
  color: $color-purple !important;
}

.background-faint-purple {
  background-color: transparentize($color-purple, 0.9);
}

.background-pink {
  background-color: $color-pink !important;
}

.border-top-pink {
  border-top: 6px solid $color-pink !important;
}

.color-pink {
  color: $color-pink !important;
}

.background-yellow {
  background-color: $color-yellow !important;
}

.background-faint-yellow {
  background-color: transparentize($color-yellow, 0.9);
}

.background-dark-blue {
  background-color: $branding-color-dark-blue !important;
}

.background-dark-purple {
  background-color: $branding-color-dark-purple !important;
}

.background-transparent {
  background-color: transparent !important;
}

.background-gray {
  background-color: $color-gray !important;
}

.background-light-gray {
  background-color: $branding-color-gray !important;
}

.border-top-light-gray {
  border-top: 6px solid $branding-color-gray !important;
}

.background-lighter-gray {
  background-color: $color-lighter-gray !important;
}

.background-near-white {
  background-color: rgba(0, 0, 0, .05) !important;
}

.background-tint-blue {
  background-color: $tint-blue;
}

.background-tint-dark-blue {
  background-color: $tint-dark-blue;
}

.background-tint-yellow {
  background-color: $tint-yellow;
}

.background-tint-orange {
  background-color: $tint-orange;
}

.background-tint-green {
  background-color: $tint-green;
}

.background-tint-purple {
  background-color: $tint-purple;
}

.white-bg {
  background-color: white !important;
}

.white-bg-80 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.white-bg-60 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.white-bg-20 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.primary-bg {
  background-color: var(--primary-color) !important;
}

.svg-fill-blue svg use {
  fill: var(--primary-color) !important;
}

.svg-fill-gray svg use {
  fill: $color-gray !important;
}

.svg-fill-light-gray svg use {
  fill: $branding-color-gray !important;
}

.svg-fill-black svg use {
  fill: $branding-color-black !important;
}

.border-color-light-gray, .button.border-color-light-gray {
  border-color: $branding-color-gray;
}

.border-color-lighter-gray {
  border-color: $color-lighter-gray;
}

.border-color-blue {
  border-color: $branding-color-blue;
}

.border-primary {
  border: 1px solid var(--primary-color) !important;
}

.border-color-primary {
  border-color: var(--primary-color) !important;
}

.border-near-black {
  border: 1px solid rgba(0, 0, 0, .6) !important;
}

.border-green {
  border: 1px solid $color-green !important;
}

.border-orange {
  border: 1px solid $color-orange !important;
}

.border-yellow {
  border: 1px solid $color-yellow !important;
}

.border-red {
  border: 1px solid $color-red !important;
}

.border-color-green, .button.border-color-green {
  border-color: $color-green !important;
}

.border-color-purple {
  border-color: $color-purple !important;
}

.border-color-red {
  border-color: $color-red !important;
}

.border-color-orange {
  border-color: $color-orange !important;
}

.border-color-tint-dark-blue {
  border-color: $tint-dark-blue;
}

.border-top-1-gray {
  border-top: 1px solid $color-lighter-gray;
}

.border-bottom-1-gray {
  border-bottom: 1px solid $color-lighter-gray;
}

.border-gray {
  border: 1px solid gray !important;
}

.bt-4-indigo {
  border-top: 2px solid $color-purple !important;
}

.theme-indigo {
  color: $color-purple;
}

.bg-theme-indigo {
  background-color: $color-purple;
}

.bg-theme-green {
  background-color: $color-green;
}


.theme-orange {
  color: $color-orange;
}

.bt-4-orange {
  border-top: 2px solid $color-orange !important;
}

.bg-theme-orange {
  background-color: $color-orange;
}

.b-active-orange {
  @include mixins.activeBorder($color-orange);
}

.unavailable-opacity { // not technically colour but ya know
  opacity: 0.4;
  pointer-events: none;
}

