@use "sass:meta";
@use 'variables' as *;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');
@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'css-star-rating/scss/star-rating.scss';

@include meta.load-css('scss');

.toast-info {
  border-radius: 10px !important;
}

.mat-checkbox .mat-checkbox-frame {
  border: 1px solid rgba(0, 0, 0, .30) !important;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, .3) !important;
}

.bs-chevron {
  margin: auto !important;
}

ngx-duration-picker {
  table {
    width: 80%;
  }
}

.iti__flag {
  background-image: url("~intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("~intl-tel-input/build/img/flags@2x.png");
  }
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.iti__country-list {
  max-width: 400px;
  max-height: 180px;
  @media only screen and (max-width: 900px) {
    max-width: 350px;
  }
  @media only screen and (max-width: 350px) {
    max-width: 300px;
  }
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

ul.legend-labels {
  min-width: 130px !important;
}

.ionicon {
  transform: translateX(1px);
}

@supports (padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

.mat-form-field-suffix, .mat-form-field-prefix {
  .mat-icon {
    font-size: 150%;
    line-height: 1.125 !important;
  }
}

.buttons_input {
  button.clear {
    display: none !important;
  }

  button.btn {
    border-radius: 10px;
    padding: 0 12px;
    background-color: $branding-primary !important;
  }

  button.active {
    background-color: $branding-primary !important;
  }
}

.md-drppicker {
  font-family: "Nunito Sans", sans-serif !important;
  width: auto !important;

  .ranges ul li button.active {
    background-color: $branding-primary !important;
  }

  td.active, td.active:hover {
    background-color: $branding-primary !important;
  }
}

.date-range-picker {
  border-color: #0000001f !important;
  height: 51px;
  font-size: 16px;
  padding-top: 11px !important;
  color: rgba(0, 0, 0, .65) !important;
  min-width: 300px;

  &.date-time {
    min-width: 400px !important;
  }
}

button.mat-tab-header-pagination {
  z-index: 1 !important;
}

.mat-snack-bar-container {
  max-width: 800px !important;
  background-color: rgb(43, 36, 97);
}




