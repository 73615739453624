@mixin boxShadow($shadow...) {
  box-shadow: $shadow;
  transition: box-shadow 0.2s ease-in-out;
}

.shadow-light {
  @include boxShadow(rgba(17, 12, 46, 0.15) 0px 48px 100px 0px);
}

.shadow-airbnb {
  @include boxShadow(rgba(0, 0, 0, 0.08) 0 4px 12px);
}

.shadow-base {
  @include boxShadow(rgba(67, 71, 85, 0.27) 0 0 0.25em, rgba(90, 125, 188, 0.05) 0 0.25em 1em);
}

.shadow-soft {
  @include boxShadow(0 1px 8px 0 rgba(0, 0, 0, 0.05));
}

.shadow-md {
  @include boxShadow(0 2px 6px 0 rgba(0, 0, 0, 0.16));
}

.shadow-lg {
  @include boxShadow(0 2px 10px 0 rgba(0, 0, 0, 0.24));
}

.shadow-hovered {
  @include boxShadow(rgba(0, 0, 0, 0.2) 0px 12px 30px -8px);
}
