@use 'mixins';
@use 'variables' as *;

.br-10 {
  border-radius: 10px !important;
}

.border-radius-10 {
  border-radius: 10px;
}

.b-2 {
  border: 2px solid transparent;
}

.b-1 {
  border: 1px solid !important;
}

.b-active-indigo {
  @include mixins.activeBorder($color-purple);
}

.theme-green {
  color: $color-green;
}

.bt-4-green {
  border-top: 2px solid $color-green;
}

.b-active-green {
  @include mixins.activeBorder($color-green);
}

.border-none {
  border: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

