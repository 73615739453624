@use 'variables' as *;

h1, h2, h3, h4, h5, h6 {
  color: $branding-color-black;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link, .title h2 {
  font-weight: 400 !important;
}

.mat-typography h2 {
  font-size: 24px;
}

body, ul, li, .mat-dialog-container {
  color: $color-gray;
}

p {
  @media only screen and (max-width: 520px) {
    font-size: 15px;
  }
}

small {
  font-size: 13px;
  font-weight: 400;
  color: $color-gray;
  font-family: "Nunito Sans", sans-serif;
}

strong {
  font-weight: 500;
}


.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.font-size-17 {
  font-size: 17px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12, .small-font-size-12 small {
  font-size: 12px;
}

.fs-12-sm {
  @media only screen and (max-width: 520px) {
    font-size: 12px;
  }
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

h4, h5, h6 {
  &.mat-subheader {
    font-weight: 600 !important;
    font-size: 16px !important;
    margin-bottom: 4px !important;
  }
}
