@use 'mixins';
@use 'variables' as *;

.theme-indigo-chip {
  @include mixins.themeChip($color-purple);
}

.theme-yellow-chip {
  @include mixins.themeChip($color-yellow);
}

.theme-yellow-outline-chip {
  @include mixins.themeChip($color-yellow, true);
}

.theme-green-chip {
  @include mixins.themeChip($color-green);
}

.theme-green-outline-chip {
  @include mixins.themeChip($color-green, true);
}

.theme-blue-chip {
  @include mixins.themeChip($branding-color-blue);
}

.theme-dark-purple-chip {
  @include mixins.themeChip($branding-color-dark-purple);
}

.theme-blue-outline-chip {
  @include mixins.themeChip($branding-color-blue, true);
}

.theme-red-chip {
  @include mixins.themeChip($color-red);
}

.theme-red-outline-chip {
  @include mixins.themeChip($color-red, true);
}

.theme-indigo-outline-chip {
  @include mixins.themeChip($color-purple, true);
}

.grey-chip {
  @include mixins.themeChip($branding-color-gray);
}

.theme-orange-chip {
  @include mixins.themeChip($color-orange);
}

.theme-orange-outline-chip {
  @include mixins.themeChip($color-orange, true);
}

.inline-badge {
  display: inline-block;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100%;
}
