@use 'variables' as *;
@use 'shadows';

body {
  margin: 0;
  //background: #F3F7FF;
  background-color: $branding-background-color !important;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
}

.display-inline {
  display: inline;
}

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.display-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.align-content-center {
  align-content: center;
}

.align-items-center {
  align-items: center;
}


.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.h-100 {
  height: 100%;
}

.width-auto {
  width: auto;
}

.width-100 {
  width: 100%;
}

@for $i from 1 through 100 {
  .w-#{$i} {
    width: $i * 1% !important;
  }
}

.w-100--sm {
  @media only screen and (max-width: 520px) {
    width: 100% !important;
  }
}

.w-80--sm {
  @media only screen and (max-width: 520px) {
    width: 80% !important;
  }
}

.width-90 {
  width: 90%;
}

.width-66 {
  width: 66.66%;
}


.width-50 {
  width: 50% !important;
}

.width-33 {
  width: 33.33% !important;
}

.full-min-height {
  min-height: 100%;
}

.translate-center {
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.translate-y-center {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}


.position-relative {
  position: relative;
}

.relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute {
  position: absolute;
}


@media screen and (max-width: 1280px) {
  .width-mid-100 {
    width: 100% !important;
  }

  .width-mid-66 {
    width: 66.66% !important;
  }

  .width-mid-50 {
    width: 50% !important;
  }

  .width-mid-33 {
    width: 33.33% !important;
  }
}

@media screen and (max-width: 991px) {
  .width-tablet-100 {
    width: 100% !important;
  }

  .width-tablet-66 {
    width: 66.66% !important;
  }

  .width-tablet-50 {
    width: 50% !important;
  }

  .width-tablet-33 {
    width: 33.33% !important;
  }
}

@media screen and (min-width: 1281px) {
  .full-min-height-desktop {
    min-height: 100%;
  }

  .desktop-flex-column {
    flex-direction: column;
  }
}


.grid-columns {
  display: flex;
  flex-wrap: wrap;
}


.flex-table .table-row {
  display: flex;

  &:not(.head) {
    transition: box-shadow 300ms ease;
    box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;

    &:hover {
      cursor: pointer;
      @extend .shadow-airbnb;
    }
  }
}

.flex-table .table-row.head {
  padding: 16px;
}

.flex-table .sort-box {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 10px;
}

.flex-table .sort-box .mat-icon {
  position: absolute;
  top: 46%;
  left: -2px;
  width: 16px;
  height: 16px;
}

.flex-table .sort-box .mat-icon:first-child {
  transform: rotate(180deg) translateX(0.7px) translateY(15px);
}

.flex-table .sort-box .mat-icon:last-child {
  transform: translateY(-3px);
}

.flex-table .sort-link .mat-icon {
  vertical-align: middle;
  padding-bottom: 6px;
  margin-left: 6px;
}

.flex-table .table-row.body:not(:last-child) {
  margin-bottom: 12px;
}

.flex-table .table-row.body {
  border-radius: 10px;
  padding: 13px 21px 13px 16px;
  align-items: center;
  position: relative;
}

.flex-table .table-row.body .indicator, .flex-table .table-row.body .indicator:after {
  position: absolute;
  top: 0;
  bottom: 0;
}

.flex-table .table-row.body .indicator {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 4px;
  left: 0;
}

.flex-table .table-row.body:not(.checked) {
  background: #fff;
}

.flex-table .table-row.body.checked {
  border-width: 2px;
  border-style: solid;
  padding: 11px 19px 11px 14px;
}

.flex-table .table-cell {
  flex: 1;
}

.flex-table .table-cell.hover .sort-selection {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 1024px) {
  .flex-table .table-row.body {
    padding-right: 14px;
  }
}
