.m-0-auto {
  margin: 0 auto !important;
}

.mar-top-24 {
  margin-top: 24px !important;
}

.mar-top-12 {
  margin-top: 12px !important;
}

.mar-bottom-12 {
  margin-bottom: 12px !important;
}

.mar-right-16 {
  margin-right: 16px !important;
}

.mr-5p {
  margin-right: 5% !important;
}

.mr-auto {
  margin-right: auto !important;
}


.ml-auto {
  margin-left: auto !important;
}

.ml--8 {
  margin-left: -8px !important;
}

.ml--16 {
  margin-left: -16px !important;
}

.ml--24 {
  margin-left: -24px !important;
}

.ml--42 {
  margin-left: -42px !important;
}

.ml--48 {
  margin-left: -48px !important;
}

.ml-5p {
  margin-left: 5% !important;
}

.mar-left-16 {
  margin-left: 16px !important;
}

.col.margin-bottom {
  margin-bottom: 21px;
}

@media screen and (max-width: 991px) {
  .col.margin-bottom-tablet-downwards {
    margin-bottom: 21px;
  }
}

.p-40 {
  padding: 40px !important;

  &.p-20--lt-md {
    @media only screen and (max-width: 520px) {
      padding: 20px !important;
    }
  }
}

.p-auto-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-0-auto {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@mixin padding($size, $where: '') {
  @if ($where == '') {
    padding: $size !important;
  } @else {
    padding-#{$where}: $size !important;
  }
}


@for $i from 0 through 60 {
  .pt-#{$i} {
    @include padding($i * 1px, 'top');
  }
  .pr-#{$i} {
    @include padding($i * 1px, 'right');
  }
  .pb-#{$i} {
    @include padding($i * 1px, 'bottom');
  }
  .pl-#{$i} {
    @include padding($i * 1px, 'left');
  }
  .plr-#{$i} {
    @include padding($i * 1px, 'left');
    @include padding($i * 1px, 'right');
  }
  .p-#{$i} {
    @include padding($i * 1px);
  }
}

@mixin margin($size, $where: '') {
  @if ($where == '') {
    margin: $size !important;
  } @else {
    margin-#{$where}: $size !important;
  }
}

@for $i from 0 through 60 {
  .mt-#{$i} {
    @include margin($i * 1px, 'top');
  }
  .mr-#{$i} {
    @include margin($i * 1px, 'right');
  }
  .mb-#{$i} {
    @include margin($i * 1px, 'bottom');
  }
  .ml-#{$i} {
    @include margin($i * 1px, 'left');
  }
  .m-#{$i} {
    @include margin($i * 1px);
  }
}

.mt-auto {
  @include margin(auto, 'top');
}

.pre-wrap {
  white-space: pre-wrap !important;
}
