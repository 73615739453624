@use 'variables' as *;
@use '@angular/material' as mat;
@use 'media' as *;

@include mat.core();

$cr-bluepallete: (
        50: #e0f2ff,
        100: var(--primary-color),
        200: var(--primary-color),
        300: var(--primary-color),
        400: var(--primary-color),
        500: var(--primary-color),
        600: var(--primary-color),
        700: var(--primary-color),
        A100: #ffffff,
        A200: #f2f7ff,
        A400: #bfd5ff,
        A700: #a6c5ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$cr-greenpallete: (
        50 : #e0f8f1,
        100 : #b3eedd,
        200 : #80e3c6,
        300 : #4dd7af,
        400 : #26cf9e,
        500 : #00c68d,
        600 : #00c085,
        700 : #00b97a,
        800 : #00b170,
        900 : #00a45d,
        A100 : #ceffe7,
        A200 : #9bffce,
        A400 : #68ffb4,
        A700 : #4fffa8,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$cr-redpallete: (
        50 : #fde2e9,
        100 : #f9b7c8,
        200 : #f587a3,
        300 : #f1567e,
        400 : #ee3262,
        500 : #eb0e46,
        600 : #e90c3f,
        700 : #e50a37,
        800 : #e2082f,
        900 : #dd0420,
        A100 : #ffffff,
        A200 : #ffd1d5,
        A400 : #ff9ea7,
        A700 : #ff858f,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$cr-darkpallete: (
        50 : #e5e4e8,
        100 : #bdbcc6,
        200 : #9190a1,
        300 : #65637b,
        400 : #44415e,
        500 : #232042,
        600 : #1f1c3c,
        700 : #1a1833,
        800 : #15132b,
        900 : #0c0b1d,
        A100 : #5e5eff,
        A200 : #2b2bff,
        A400 : #0000f7,
        A700 : #0000de,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #ffffff,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$cr-white: (
        50: #ddddde,
        100: #ddddde,
        200: #ddddde,
        300: #ddddde,
        400: #ddddde,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #fff,
        A200: #fff,
        A400: #fff,
        A700: #fff,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: white,
                A200: white,
                A400: white,
                A700: white,
        )
);

$callroute-typography: mat.define-typography-config(
        $font-family: '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        $headline: mat.define-typography-level(33px, 1, 700),
        $title: mat.define-typography-level(24px, 1.25, 700),
        $subheading-2: mat.define-typography-level(20px, 1.21, 700),
        $subheading-1: mat.define-typography-level(22px, 1.21, 700),
        $body-1: mat.define-typography-level(16px, 1.53, 400),
        $body-2: mat.define-typography-level(19px, 1.57, 500),
        $input: mat.define-typography-level(17px, 1.53, 400),
        $button: mat.define-typography-level(17px, 1.53, 500)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$callroute-portal-primary: mat.define-palette($cr-bluepallete);
$callroute-portal-accent: mat.define-palette($cr-greenpallete);

// The warn palette is optional (defaults to red).
$callroute-portal-warn: mat.define-palette($cr-redpallete);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$callroute-portal-theme: mat.define-light-theme((
        color: (
                primary: $callroute-portal-primary,
                accent: $callroute-portal-accent,
                warn: $callroute-portal-warn,
        )
));

@include mat.all-component-themes($callroute-portal-theme);
@include mat.all-component-typographies($callroute-typography);


.mat-chip {
  font-size: 16px !important;
  font-weight: 400 !important;
}

mat-hint {
  font-size: 14px !important;
  display: block;
  margin-top: 6px;
  color: rgba(0, 0, 0, .54);
}

.mat-body-1 {
  @media only screen and (max-width: 520px) {
    font-size: 14px !important;
  }

  @media only screen and (max-width: 400px) {
    font-size: 13px !important;
  }

  &--smaller {
    line-height: 1.53em !important;
    font-size: 14px !important;
    @media only screen and (max-width: 520px) {
      font-size: 13px !important;
    }

    @media only screen and (max-width: 400px) {
      font-size: 12px !important;
    }
  }
}

.mat-progress-spinner.color-white circle, .mat-spinner.color-white circle {
  stroke: white;
}

.mat-tab-link {
  &.mat-tab-label-active {
    opacity: 1 !important;
  }
}

mat-icon {
  &.md-icon {
    height: 24px;
    width: 24px;

    svg {
      width: 24px !important;
    }
  }

  &.sm-icon {
    height: 18px;
    width: 18px;

    svg {
      width: 18px !important;
    }
  }
}

.mat-progress-spinner.color-orange circle, .mat-spinner.color-orange circle, .mat-progress-bar.color-orange {
  stroke: $color-orange;

  .mat-progress-bar-buffer {
    background-color: #f8cc9f;
  }

  .mat-progress-bar-fill:after {
    background-color: $color-orange;
  }
}

.mat-progress-spinner.color-green circle, .mat-spinner.color-green circle, .mat-progress-bar.color-green {
  stroke: $color-green;

  .mat-progress-bar-buffer {
    background-color: #baf1e1;
  }

  .mat-progress-bar-fill:after {
    background-color: $color-green;
  }
}

.mat-progress-spinner.color-primary circle, .mat-spinner.color-primary circle, .mat-progress-bar.primary, .mat-progress-bar.mat-primary {
  stroke: var(--primary-color);

  .mat-progress-bar-buffer {
    background-color: #e1e1ec;
  }

  .mat-progress-bar-fill:after {
    background-color: var(--primary-color);
  }
}

.mat-progress-spinner.color-orto-blue circle, .mat-spinner.color-orto-blue circle, .mat-progress-bar.color-orto-blue {
  stroke: $branding-orto-blue;

  .mat-progress-bar-buffer {
    background-color: #D4FBFFFF;
  }

  .mat-progress-bar-fill:after {
    background-color: $branding-orto-blue;
  }
}

.mat-progress-spinner.color-yellow circle, .mat-spinner.color-yellow circle, .mat-progress-bar.color-yellow {
  stroke: $color-yellow;
}

.mat-progress-spinner.color-red circle, .mat-spinner.color-red circle {
  stroke: $color-red;
}

.mat-tooltip {
  &.multi-line {
    white-space: pre-line;
    line-height: 1.5em;
    min-width: 300px !important;
  }
}


.option-line-height-1 .mat-option-text {
  line-height: 1.2;
}

.mat-progress-spinner.display-inline-block {
  display: inline-block;
}

mat-progress-bar .mat-progress-bar-fill {
  transition: transform 1s linear !important;
}

.mat-form-field {
  @include ltMd {
    font-size: 15px;
  }
}

.mat-checkbox.full-width {
  width: 100%;
  display: block;
}

.mat-checkbox.full-width .mat-checkbox-layout {
  width: 100%;
}

.mat-checkbox.full-width .mat-checkbox-layout .mat-checkbox-inner-container {
  margin-left: 0;
}

.mat-bottom-sheet-container-medium {
  &.cr-padded-sheet {
    &.mat-bottom-sheet-container {
      min-width: 460px !important;
    }
  }
}

.mat-bottom-sheet-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $color-gray;
}

.mat-bottom-sheet-container.cr-padded-sheet {
  padding: 43px 30px 49px;
}

.mat-button-toggle-group.cr-blank-toggle-group {
  border: none;
  flex-direction: column;
  width: 100%;
}

.mat-button-toggle-group.cr-blank-toggle-group .mat-button-toggle-label-content {
  display: flex;
  align-items: center;
  line-height: 1.57;
  font-size: 14px;
  padding: 0;
}

.mat-button-toggle-group.cr-blank-toggle-group .mat-button-toggle-checked {
  background: none;
}

.mat-button-toggle-group.cr-blank-toggle-group .mat-button-toggle:not(:first-child) {
  border-left: none;
}

span.mat-badge-content {
  color: white !important;
}

.mat-option {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 16px;
}

.mat-tooltip {
  font-size: 13px;
  color: #fff;
  background: $branding-color-dark-blue;
}

@media screen and (min-width: 1025px) {
  .mat-tooltip.hide-desktop {
    display: none;
  }
}


.mat-checkbox.white-space-normal .mat-checkbox-label {
  white-space: normal;
}

.mat-checkbox.break-word .mat-checkbox-label {
  word-break: break-word;
}

.mat-checkbox.layout-full-width-normal .mat-checkbox-layout {
  width: 100%;
  white-space: normal;
  display: inline-block;
}

.mat-checkbox-checked.checked-content-black .mat-checkbox-label {
  color: $branding-color-black;
}

.mat-checkbox-layout .mat-checkbox-label {
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: $branding-color-gray;
  border-radius: 4px;
  border-width: 1px;
}


.field-invalid.mat-checkbox .mat-checkbox-frame {
  border-color: $color-red;
}

.cr-checkbox-box-mar-10 .mat-checkbox-inner-container {
  margin-right: 10px;
}

.cr-checkbox-box-mar-16 .mat-checkbox-inner-container {
  margin-right: 16px;
}


.cr-checkbox-size-20 .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

.cr-checkbox-standard-line-height .mat-checkbox-label {
  line-height: 24px {
  }
}

.mat-menu-panel.provisioning-menu {
  width: 400px;
  max-width: 400px;
  @include ltSm {
    width: 300px;
    max-width: 300px;
  }
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-right: 5px;
}

.mat-menu-panel.menu-popup {
  max-width: 252px;
  width: 252px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px 0 10px 10px;
  margin-right: 5px;
}

.mat-menu-panel.menu-popup .mat-menu-content {
  padding: 0;
}

.mat-menu-panel.menu-popup .profile-container {
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid $color-lighter-gray;
}

.mat-menu-panel.menu-popup .profile-container .info {
  padding-left: 16px;
}

.mat-menu-panel.menu-popup .mat-menu-item {
  padding: 0 20px;
  color: $color-gray;
}

.mat-menu-panel.menu-popup .mat-menu-item:last-child {
  border-top: 1px solid $color-lighter-gray;
}

.mat-menu-item, .mat-option-text {
  @include ltLg {
    font-size: 15px !important;
  }
}

/** Notification popup */
.mat-menu-panel.notification-popup {
  position: fixed;
  top: 10vh;
  right: 5vw;
  background: none;
  box-shadow: none;
  width: 400px;
  max-width: none;
  overflow: visible;
  z-index: 10000;
}

@media screen and (max-width: 512px) {
  .mat-menu-panel.notification-popup {
    width: 90vw;
    margin: 0 5vw;
  }
}

/**
 *  Modals
 */

.cdk-overlay-pane.cr-dialog {
  margin-left: 16px;
  margin-right: 16px;
}

.cdk-overlay-pane.cr-dialog .mat-dialog-container {
  padding: 46px 40px 46px;
  @include ltMd {
    padding: 40px 32px 40px;
  }
  border-radius: 10px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.08);
}

.cdk-overlay-pane.cr-dialog .mat-dialog-container .mat-dialog-actions {
  margin-bottom: 0;
  padding-bottom: 0;
}

.cdk-overlay-pane.cr-dialog .mat-dialog-content {
  max-height: none;
  margin: 0;
  padding: 0;
}

.cr-dialog .dialog-head h2 {
  width: 100%;
}

.cr-dialog .dialog-head .button {
  min-width: 0;
  line-height: 1;
}

.cr-dialog .dialog-head img {
  vertical-align: middle;
}

.cr-dialog .dialog-content-container {
  padding: 30px 30px 45px;
}

.cr-dialog .max-width-content {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.mat-datepicker-content .mat-calendar {
  width: 296px;
  height: 420px !important;
}

.mat-tab-body-active, .mat-tab-body-content, .mat-tab-body-wrapper {
  overflow: visible !important;
}

.mat-chip-avatar {
  transform: scale(0.8) !important;
  overflow: visible !important;
}
