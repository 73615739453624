.no-pointer-events {
  pointer-events: none !important;
}

.pointer-events-all {
  pointer-events: all !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}
