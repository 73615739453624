$branding-orto-blue: #00ecff;
$branding-color-blue: #17d4f0;
$branding-color-violet: #a129e3;
$branding-color-near-black: #25262b;
$branding-color-dark-blue: #2b2461;
$branding-color-gray: #e3e3e3;
$branding-color-light-gray: #f2f2f2;
$branding-color-mint: #08d1d6;
$branding-color-teal: #17a8c7;
$branding-purple: #825cf5;
$branding-color-dark-purple: #6b4acc;
$branding-color-indigo: #5055a1;
$branding-color-black: #2e2621;

$branding-primary: $branding-color-dark-purple;
$branding-background-color: $branding-color-light-gray;

$color-green: #00C68D;
$color-red: #EB0E46;
$color-orange: #EC7800;
$color-yellow: #F0BB00;
$color-gray: #575B5E;
$color-purple: #5B4CFF;
$color-pink: #f544ae;
$color-lighter-gray: #e8e8e8;
$color-light-gray: #a7a5a5;

$light-grey-text: rgba(0, 0, 0, 0.54);
$med-grey-text: rgba(0, 0, 0, 0.64);

$tint-green: #F2FCF9;
$tint-blue: #E5F4FF;
$tint-red: #FEF1F4;
$tint-orange: #FEF9EA;
$tint-yellow: #EFEFEF;
$tint-dark-blue: #F4F7FD;
$tint-gray: #F3F2FF;
$tint-purple: #F1F4F5;

:root {
  --primary-color: $branding-primary;
}
