@use 'variables' as *;

.button {
  margin: 0;
}


button {
  font-family: 'Nunito Sans', sans-serif;
}

a.button, .button, input[type="submit"] {
  border-radius: 22px;
  padding: 8px 26px;
  color: #fff;
  cursor: pointer;
  transition: 0.1s all ease;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.6;
  min-width: 150px;
  @media only screen and (max-width: 520px) {
    min-width: 120px;
    font-size: 14px;
    padding: 6px 22px;
  }
  white-space: nowrap;
  font-family: "Nunito Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

button.button {
  @media only screen and (max-width: 520px) {
    height: 40px;
  }

  &.sm {
    height: 40px;
    min-width: 100px;
    font-size: 14px;
  }
}

a.button .mat-icon, .button:not(.svg-override) .mat-icon, input[type="submit"] .mat-icon,
a.button .mat-icon svg, .button:not(.svg-override) .mat-icon svg, input[type="submit"] .mat-icon svg {
  width: 24px;
  font-size: 24px;
  line-height: 26px;
  height: 24px;
}

a.button .ionicon, .button:not(.svg-override) .ionicon, input[type="submit"] .ionicon,
a.button .ionicon svg, .button:not(.svg-override) .ionicon svg, input[type="submit"] .ionicon svg {
  width: 24px;
  font-size: 24px;
  line-height: 26px;
  height: 24px;
}


.button.svg-override.left-icon .mat-icon {
  padding-right: 6px;
  vertical-align: middle;
}

.button.svg-override.left-icon span {
  vertical-align: middle;
}

a.button mat-spinner, .button mat-spinner {
  margin: 0 auto;
}

button:disabled mat-spinner.in-progress circle {
  stroke: gray;
}

a.button:focus, .button:focus, input[type="submit"]:focus {
  outline: none;
}

button {
  vertical-align: middle;
}

/** Primary button */
a.button.primary, .button.primary, input[type="submit"].primary {
  background-color: var(--primary-color);
}

a.button.primary mat-icon use, .button.primary mat-icon use, input[type="submit"].primary mat-icon use {
  fill: #fff;
}

a.button.primary:hover, .button.primary:hover, input[type="submit"].primary:hover {
  background-color: #fff;
  color: var(--primary-color);
}

a.button.primary:hover mat-icon use, .button.primary:hover mat-icon use, input[type="submit"].primary:hover mat-icon use {
  fill: var(--primary-color);
}

/** Secondary button */
a.button.secondary, .button.secondary {
  background-color: #fff;
  color: var(--primary-color);
}

a.button.dark, .button.dark {
  background-color: $branding-color-dark-blue;
  color: #fff;
}

a.button.dark:hover, .button.dark:hover {
  background-color: #fff;
  color: $branding-color-dark-blue;
}

a.button.secondary mat-icon use, .button.secondary mat-icon use {
  fill: var(--primary-color);
}

a.button.secondary:hover, .button.secondary:hover {
  background-color: var(--primary-color);
  color: #fff;
}

a.button.secondary:hover mat-icon use, .button.secondary:hover mat-icon use {
  fill: #fff;
}

/** Tertiary button */
a.button.tertiary, .button.tertiary {
  background-color: #fff;
  color: $branding-color-black;
  border-color: $branding-color-black;
}

a.button.tertiary:hover, .button.tertiary:hover {
  background-color: $branding-color-black;
  color: #fff;
}

/** Ghost button */
a.button.ghost, .button.ghost {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}

a.button.ghost:hover, .button.ghost:hover {
  background-color: #fff;
  color: $branding-color-black;
}

a.button.toggle, .button.toggle {
  border-radius: 14px;
  background-color: transparent;
  color: $color-gray;
  font-size: 12px;
  padding: 6px 12px;
  min-width: 0;
  font-weight: normal;
  border-color: transparent;
}

a.button.toggle:hover, .button.toggle:hover {
  color: $branding-color-black;
  background-color: $tint-blue;
}

a.button.toggle.active, .button.toggle.active {
  border-color: var(--primary-color);
  color: $branding-color-black;
  font-weight: bold;
}

a.button.primary:disabled, .button.primary:disabled, input[type="submit"].primary:disabled,
a.button.secondary:disabled, .button.secondary:disabled, input[type="submit"].secondary:disabled {
  background: #f2f2f2 !important;
  border-color: #d8d8d8 !important;
  color: #575b5e80 !important;
  cursor: default;
}

a.button.primary:disabled mat-icon use, .button.primary:disabled mat-icon use,
a.button.secondary:disabled mat-icon use, .button.secondary:disabled mat-icon use {
  fill: #575b5e80 !important;
}

a {
  &.link {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: darken(#015c9c, 40%);
    }

    &[class=color-near-black] {
      &:hover {
        color: var(--primary-color);
      }
    }

    &.disabled {
      color: $branding-color-gray !important;
    }
  }
}

/** Link button */
button.link {
  padding: 0;
  color: var(--primary-color);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

button.link.standard-font {
  font-size: 17px;
  line-height: 1.53;
}

button.link:disabled {
  color: #d8d8d8;
}

small button.link {
  font-size: 13px;
}

button.link.gray {
  color: $color-gray;
}

button.link.white {
  color: #fff;
}

.button.icon .mat-icon, .button.icon .mat-icon svg {
  width: 50px;
  height: 50px;
}

button {
  &.button {

    &.primary {
      border: var(--primary-color) 1px solid;

      &:not(:hover) {

        &:not([disabled]) {
          mat-spinner, mat-progress-spinner {
            circle {
              stroke: white;
            }
          }
        }
      }
    }

    &.secondary {
      border: var(--primary-color) 1px solid;

      &:hover {

        &:not([disabled]) {
          mat-spinner, mat-progress-spinner {
            circle {
              stroke: white;
            }
          }
        }
      }
    }

    &.dark {
      border: $branding-color-dark-blue 1px solid !important;

      &:hover {

        &:not([disabled]) {
          mat-spinner, mat-progress-spinner {
            circle {
              stroke: white;
            }
          }
        }
      }
    }

  }
}

