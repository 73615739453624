@use 'variables' as *;

input, textarea, select {
  &:not(.mat-input-element) {
    border-radius: 4px;
    border: 1px solid $branding-color-gray;
    background-color: #fff;
    line-height: 1.53;
    padding: 8px 16px;
    color: $branding-color-dark-blue;
    transition: border 0.3s ease;
    -webkit-appearance: none;
    font-family: "Nunito Sans", sans-serif;
  }

  font-size: 17px;
  @media only screen and (max-width: 420px) {
    font-size: 15px;
  }

}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 0.55em;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-infix {
  border-top: 0.7em solid #0000;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.80em;
  margin-top: -0.22em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.5em) scale(.75);
}

.mat-form-field-label-wrapper {
  overflow: visible !important;

  label {
    margin-top: 2px;
  }
}

input:disabled, textarea:disabled, select:disabled {
  border-color: #d8d8d8;
  color: #d8d8d8;
}

input {
  &::placeholder {
    color: rgba(0, 0, 0, .35);
  }
}

select {
  padding-right: 24px;
  font-family: "Nunito Sans", sans-serif;
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

.mat-date-range-input-start-wrapper {
  input {
    margin-top: 0;
  }
}

.mat-date-range-input-wrapper {
  margin-top: 8px !important;
}

.date-picker {
  &__prefix {
    transform: translateY(-4px);
  }
}

input:active, textarea:active, select:active, input:focus, textarea:focus, select:focus {
  &:not(.mat-input-element) {
    outline: none;
    border: 1px solid $branding-color-blue;
  }
}

.input-field-container {
  display: flex;
  flex-direction: column;
}

.input-field-container:not(.custom-margin) {
  margin-bottom: 16px;
}

.input-field-container:not(.custom-margin):last-of-type {
  margin-bottom: 12px;
}

.input-field-container.m-0, .input-field-container.m-0:last-of-type {
  margin-bottom: 0;
}

.input-field-container .field-label {
  font-size: 14px;
  color: $light-grey-text;
  margin-bottom: 8px;
  display: block;
  font-weight: normal;
  position: relative;

  &--bold {
    color: $branding-color-black;
    font-weight: 700;
    font-size: 17px;
  }
}

.input-field-container input.field-invalid {
  border-color: $color-red !important;
}

.input-field-container input.ng-dirty.ng-valid {
  border-color: $color-green;
}

.input-field-container.left-icon,
.input-field-container.right-icon {
  position: relative;
}

.input-field-container.left-icon > input {
  padding-left: 44px;
}

.input-field-container.right-icon > input {
  padding-right: 44px;
}

.input-field-container .icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -o-transform: translatey(-50%);
}

.input-field-container .icon.small {
  width: 16px;
  height: 16px;
}

.input-field-container.left-icon .icon {
  left: 10px;
}

.input-field-container.right-icon .icon {
  right: 10px;
}

.panel-header.select select, select.cr-select {
  float: right;
  padding: 8px 44px 8px 17px;
  background-size: auto;
  background: #fff url('https://cr-web-assets.s3.eu-west-2.amazonaws.com/icons/chevron-down-black.svg') no-repeat calc(100% - 10px) calc(50% + 6px);
}

.panel-header.select .title {
  display: inline-block;
}

.panel-header.select select option, select.cr-select option {
  -webkit-appearance: none;
}


@media screen and (max-width: 370px) {
  .panel-header.select select, select.cr-select {
    width: 100%;
  }

  .panel-header.select:not(.m-0) select, select.cr-select:not(.m-0) {
    margin-top: 16px;
  }
}


.mat-option {
  &__inner {
    height: 42px;

    &__name {
      line-height: 1.1rem;
    }

    &__description {
      line-height: 1.1rem;
    }
  }
}

