
.ngxp__inner, .ngxp__container {
  padding: 0 !important;
  cursor: auto;
  border-radius: 8px !important;
}


.popover-header {

  h4 {
    white-space: break-spaces;
    word-wrap: break-word;
    color: #333;
  }
}


.popover-header {
  padding: 20px 20px 10px 20px;
  border-radius: 8px 8px 0 0;
}

.popover-body {
  padding: 10px 20px 20px 20px;
}

popper-content {
  div.ngxp__container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid rgb(225, 228, 232) !important;
    min-width: 400px;
    @media only screen and (max-width: 400px) {
      min-width: 95vw;
    }

    > .ngxp__arrow {
      z-index: 0;

      &::before {
        border: 1px solid rgba(225, 228, 232, 0.8);
      }
    }

    &[data-popper-placement^='bottom'] > .ngxp__arrow {
      &::before {
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
    }

    &[data-popper-placement^='top'] > .ngxp__arrow {
      &::before {
        border-top-color: transparent;
        border-left-color: transparent;
      }
    }

    &[data-popper-placement^='left'] > .ngxp__arrow {
      &::before {
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }

    &[data-popper-placement^='right'] > .ngxp__arrow {
      &::before {
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }
}
