@use 'variables';

@mixin activeBorder($borderColor: $color-green) {
  border: 2px solid $borderColor;
  border-top-width: 4px;
  transition: border-color 0.3s ease-in-out;
}

@mixin themeChip($themeColor: variables.$branding-color-blue, $outline: false) {
  border-radius: 20px !important;
  @if $outline {
    background-color: white !important;
    border: 1px $themeColor solid !important;
    color: $themeColor !important;
  } @else {
    background-color: $themeColor !important;
    color: white !important;
  }
}
