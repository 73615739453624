$ltXs: 599px;
$ltSm: 959px;
$ltMd: 1279px;
$ltLg: 1919px;

@mixin ltXs {
  @media only screen and (max-width: $ltXs) {
    @content;
  }
}

@mixin ltSm {
  @media only screen and (max-width: $ltSm) {
    @content;
  }
}

@mixin ltMd {
  @media only screen and (max-width: $ltMd) {
    @content;
  }
}

@mixin ltLg {
  @media only screen and (max-width: $ltLg) {
    @content;
  }
}

@mixin isMobileMode {
  @media screen and (max-width: 767px), (max-height: 601px) {
    @content;
  }
}
